/*--------------------------------------------------
Font Face
---------------------------------------------------*/
@font-face {
	font-family: 'Expletus Sans';
  src: url("../fonts/expletussans-regular-webfont.eot");
  src: url("../fonts/expletussans-regular-webfont.eot?#iefix") format("embedded-opentype"),
       url("../fonts/expletussans-regular-webfont.woff2") format("woff2"),
       url("../fonts/expletussans-regular-webfont.woff") format("woff"),
       url("../fonts/expletussans-regular-webfont.ttf") format("truetype"),
       url("../fonts/expletussans-regular-webfont.svg#expletus_sansregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
	font-family: 'Expletus Sans';
  src: url("../fonts/expletussans-bold-webfont.eot");
  src: url("../fonts/expletussans-bold-webfont.eot?#iefix") format("embedded-opentype"),
       url("../fonts/expletussans-bold-webfont.woff2") format("woff2"),
       url("../fonts/expletussans-bold-webfont.woff") format("woff"),
       url("../fonts/expletussans-bold-webfont.ttf") format("truetype"),
       url("../fonts/expletussans-bold-webfont.svg#expletus_sansbold") format("svg");
  font-weight: bold;
  font-style: normal;
}
/*--------------------------------------------------
Basscss Base Buttons
---------------------------------------------------*/
button,
.button {
	text-decoration: none;
	cursor: pointer;
	display: inline-block;
	box-sizing: border-box;
	line-height: 1.125;
	padding: 0.5em 1rem;
	margin: 0;
	letter-spacing: 0.09rem;
	height: auto;
	/*border: 1px solid transparent;*/
	-webkit-appearance: none;
}

::-moz-focus-inner {
	border: 0;
	padding: 0;
}

.button:hover {
	text-decoration: none;
}

/*--------------------------------------------------
Basscss Base Forms
---------------------------------------------------*/
input,
select,
textarea,
fieldset {
	margin-top: 0;
	margin-bottom: 0.5rem;
}

input[type=text],
input[type=datetime],
input[type=datetime-local],
input[type=email],
input[type=month],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=time],
input[type=url],
input[type=week] {
	box-sizing: border-box;
	height: 2.25em;
	padding: 0.25em 1rem;
	-webkit-appearance: none;
}

select {
	box-sizing: border-box;
	line-height: 1.75;
	padding: 0.25em 1rem;
}

select:not([multiple]) {
	height: 2.25em;
}

textarea {
	box-sizing: border-box;
	line-height: 1.75;
	padding: 0.25em 1rem;
}

.fieldset-reset {
	padding: 0;
	margin-left: 0;
	margin-right: 0;
	border: 0;
}

.fieldset-reset legend {
	padding: 0;
}

/*--------------------------------------------------
Basscss Base Reset
---------------------------------------------------*/
body,
button {
	margin: 0;
}

button,
input,
select,
textarea {
	font-family: inherit;
	font-size: 100%;
}

img {
	max-width: 100%;
}

svg {
	max-height: 100%;
}

figure {
	margin: 0;
}

/*--------------------------------------------------
Basscss Base Tables
---------------------------------------------------*/
table {
	border-collapse: collapse;
	border-spacing: 0;
	max-width: 100%;
	width: 100%;
}

th {
	text-align: left;
	font-weight: bold;
}

th,
td {
	padding: 0.25em 1rem;
	line-height: inherit;
}

th {
	vertical-align: bottom;
}

td {
	vertical-align: top;
}

/*--------------------------------------------------
Basscss Base Typography
---------------------------------------------------*/
body {
	font-family: "Expletus Sans", Helvetica, sans-serif;
	line-height: 1.875;
	font-size: 100%;
	-webkit-font-smoothing: antialiased;
	letter-spacing: 0.02rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Expletus Sans", Helvetica, sans-serif;
	font-weight: bold;
	line-height: 1.25;
	margin-top: 1em;
	margin-bottom: .5em;
}

p,
dl,
ol,
ul {
	font-size: 1.125rem;
	margin-top: 0;
	margin-bottom: .5rem;
}

ol,
ul {
	padding-left: 2rem;
}

pre,
code,
samp {
	font-family: "Source Code Pro", Consolas, monospace;
	font-size: inherit;
}

pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow-x: scroll;
	padding: 1rem;
}

hr {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

blockquote {
	margin-top: 2rem;
	margin-bottom: 2rem;
	margin-left: 0;
	padding-left: 1rem;
	padding-right: 1rem;
	border-left: 8px solid;
}

blockquote,
blockquote p {
	font-size: 1.75rem;
	font-style: italic;
}

img {
	border: 0 none;
	height: auto;
	max-width: 100%;
	vertical-align: middle;
}

h1,
.h1 {
	font-size: 3rem;
}

h2,
.h2 {
	font-size: 2.25rem;
}

h3,
.h3 {
	font-size: 1.75rem;
}

h4,
.h4 {
	font-size: 1.125rem;
}

h5,
.h5 {
	font-size: 1rem;
}

h6,
.h6 {
	font-size: 0.875rem;
}

.list-reset {
	list-style: none;
	padding-left: 0;
}

/*--------------------------------------------------
 Basscss Color Base
---------------------------------------------------*/
body {
	color: #5c5c5c;
}

a {
	color: #143893;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

pre,
code {
	background-color: #eee;
	border-radius: 3px;
}

hr {
	border: 0;
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: #ccc;
}

/*--------------------------------------------------
Basscss Color Borders
---------------------------------------------------*/
.border {
	border-style: solid;
	border-width: 1px;
	border-color: #ccc;
}

.border-top {
	border-top-style: solid;
	border-top-width: 1px;
	border-top-color: #ccc;
}

.border-right {
	border-right-style: solid;
	border-right-width: 1px;
	border-right-color: #ccc;
}

.border-bottom {
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: #ccc;
}

.border-left {
	border-left-style: solid;
	border-left-width: 1px;
	border-left-color: #ccc;
}

.rounded {
	border-radius: 3px;
}

.circle {
	border-radius: 50%;
}

.rounded-top {
	border-radius: 3px 3px 0 0;
}

.rounded-right {
	border-radius: 0 3px 3px 0;
}

.rounded-bottom {
	border-radius: 0 0 3px 3px;
}

.rounded-left {
	border-radius: 3px 0 0 3px;
}

.not-rounded {
	border-radius: 0;
}

/*--------------------------------------------------
Basscss Color Buttons
---------------------------------------------------*/
.button-blue {
	color: white;
	background-color: #143893;
	border-radius: 0;
	transition-duration: .1s;
	transition-timing-function: ease-out;
	transition-property: box-shadow, background-color;
}

.button-blue:hover {
	opacity: .875;
}

.button-blue:active,
.button-blue.is-active {
	box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.125), inset 0 2px 3px 0 rgba(0, 0, 0, 0.25);
}

.button-blue:focus {
	outline: none;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5), 0 0 1px 4px rgba(20, 56, 147, 0.5);
}

.button-blue:disabled,
.button-blue.is-disabled {
	opacity: .5;
}

.button-blue-outline {
	line-height: 1;
	color: #143893;
	background-color: transparent;
	border-radius: 0;
	border: 2px solid #143893;
	transition-duration: .1s;
	transition-timing-function: ease-out;
	transition-property: box-shadow, background-color;
}

.button-blue-outline:hover,
.button-blue-outline.is-active {
	color: white;
	background-color: #143893;
}

.button-blue-outline:active {
	box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.25);
}

.button-blue-outline:focus {
	outline: none;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5), 0 0 1px 4px rgba(20, 56, 147, 0.5);
}

.button-blue-outline:disabled,
.button-blue-outline.is-disabled {
	color: #143893;
	background-color: transparent;
	opacity: .5;
}

.button-gray {
	color: white;
	background-color: #777;
	border-radius: 0;
	transition-duration: .1s;
	transition-timing-function: ease-out;
	transition-property: box-shadow, background-color;
}

.button-gray:hover {
	opacity: .875;
}

.button-gray:active,
.button-gray:is-active {
	box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.125), inset 0 2px 3px 0 rgba(0, 0, 0, 0.25);
}

.button-gray:focus {
	outline: none;
	box-shadow: 0 0 0 2px white, 0 0 1px 4px rgba(20, 56, 147, 0.5);
}

.button-gray:disabled,
.button-gray.is-disabled {
	opacity: .5;
}

.button-red {
	color: white;
	background-color: #f95020;
	border-radius: 0;
	transition-duration: .1s;
	transition-timing-function: ease-out;
	transition-property: box-shadow, background-color;
}

.button-red:hover {
	opacity: .875;
}

.button-red:active,
.button-red.is-active {
	box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.125), inset 0 2px 3px 0 rgba(0, 0, 0, 0.25);
}

.button-red:focus {
	outline: none;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5), 0 0 1px 4px rgba(249, 80, 32, 0.5);
}

.button-red:disabled,
.button-red.is-disabled {
	opacity: .5;
}

.button-red-outline {
	line-height: 1;
	color: #f95020;
	background-color: transparent;
	border-radius: 0;
	border: 2px solid #f95020;
	transition-duration: .1s;
	transition-timing-function: ease-out;
	transition-property: box-shadow, background-color;
}

.button-red-outline:hover,
.button-red-outline.is-active {
	color: white;
	background-color: #f95020;
}

.button-red-outline:active {
	box-shadow: inset 0 3px 3px 0 rgba(0, 0, 0, 0.25);
}

.button-red-outline:focus {
	outline: none;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5), 0 0 1px 4px rgba(249, 80, 32, 0.5);
}

.button-red-outline:disabled,
.button-red-outline.is-disabled {
	color: #f95020;
	background-color: transparent;
	opacity: .5;
}

.button-green {
	color: white;
	background-color: #00cf26;
	border-radius: 0;
	transition-duration: .1s;
	transition-timing-function: ease-out;
	transition-property: box-shadow, background-color;
}

.button-green:hover {
	opacity: .875;
}

.button-green:active,
.button-green.is-active {
	box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.125), inset 0 2px 3px 0 rgba(0, 0, 0, 0.25);
}

.button-green:focus {
	outline: none;
	box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5), 0 0 1px 4px rgba(0, 207, 38, 0.5);
}

.button-green:disabled,
.button-green.is-disabled {
	opacity: .5;
}

.button-nav-light:hover {
	background-color: rgba(0, 0, 0, 0.0625);
}

.button-nav-light:active,
.button-nav-light.is-active {
	color: #333;
}

.button-nav-dark {
	color: white;
}

.button-nav-dark:hover {
	background-color: rgba(0, 0, 0, 0.125);
}

.button-nav-dark:active,
.button-nav-dark.is-active {
	background-color: rgba(0, 0, 0, 0.125);
}

.button-nav-tab {
	margin-bottom: -1px;
	border-radius: 0 0 0 0;
	border-bottom: 1px solid #ccc;
}

.button-nav-tab:hover {
	background-color: rgba(0, 0, 0, 0.0625);
}

.button-nav-tab.is-active {
	background-color: white;
	border-bottom: 1px solid white;
	border-top: 1px solid #ccc;
	border-left: 1px solid #ccc;
	border-right: 1px solid #ccc;
}

/*--------------------------------------------------
 Basscss Color Forms Dark
---------------------------------------------------*/
.field-dark {
	color: white;
	background-color: rgba(0, 0, 0, 0.25);
	border: 1px solid rgba(0, 0, 0, 0.0625);
	border-radius: 3px;
}

.field-dark::placeholder {
	color: rgba(255, 255, 255, 0.75);
}

.field-dark:focus {
	outline: 0;
	border: 1px solid rgba(255, 255, 255, 0.5);
}

.field-dark:read-only:not(select) {
	background-color: rgba(255, 255, 255, 0.25);
}

.field-dark:invalid {
	border-color: #f95020;
}

.field-dark.is-success {
	border-color: #00cf26;
}

.field-dark.is-warning {
	border-color: #efcc00;
}

.field-dark.is-error {
	border-color: #f95020;
}

/*--------------------------------------------------
Basscss Color Forms
---------------------------------------------------*/
.field-light {
	background-color: white;
	transition: box-shadow .2s ease;
	border-style: solid;
	border-width: 1px;
	border-color: #EFEBD5;
	border-radius: 0px;
}

.field-light:focus {
	outline: none;
	border-color: #143893;
	box-shadow: 0 0 2px rgba(20, 56, 147, 0.5);
}

.field-light:disabled {
	color: #777;
	background-color: rgba(0, 0, 0, 0.125);
}

.field-light:read-only:not(select) {
	background-color: rgba(0, 0, 0, 0.125);
}

.field-light:invalid {
	border-color: #f95020;
}

.field-light.is-success {
	border-color: #00cf26;
}

.field-light.is-warning {
	border-color: #efcc00;
}

.field-light.is-error {
	border-color: #f95020;
}

.radio-light,
.checkbox-light {
	transition: box-shadow .2s ease;
}

.radio-light {
	border-radius: 50%;
}

.radio-light:focus,
.checkbox-light:focus {
	outline: none;
	box-shadow: 0 0 2px rgba(20, 56, 147, 0.5);
}

/*--------------------------------------------------
Basscss Color Tables
---------------------------------------------------*/
th,
td {
	border-bottom-style: solid;
	border-bottom-width: 1px;
	border-bottom-color: #ccc;
}

/*--------------------------------------------------
Basscss Colors
---------------------------------------------------*/
.dark-gray {
	color: #333;
}

.white {
	color: white;
}

.blue {
	color: #143893;
}

.mid-gray {
	color: #777;
}

.light-gray {
	color: #ccc;
}

.lighter-gray {
	color: #eee;
}

.red {
	color: #f95020;
}

.green {
	color: #00cf26;
}

.yellow {
	color: #efcc00;
}

.bg-dark-gray {
	background-color: #333;
}

.bg-white {
	background-color: white;
}

.bg-blue {
	background-color: #143893;
}

.bg-mid-gray {
	background-color: #777;
}

.bg-light-gray {
	background-color: #ccc;
}

.bg-lighter-gray {
	background-color: #eee;
}

.bg-red {
	background-color: #f95020;
}

.bg-green {
	background-color: #00cf26;
}

.bg-yellow {
	background-color: #efcc00;
}

.bg-darken-1 {
	background-color: rgba(0, 0, 0, 0.0625);
}

.bg-darken-2 {
	background-color: rgba(0, 0, 0, 0.125);
}

.bg-darken-3 {
	background-color: rgba(0, 0, 0, 0.25);
}

.bg-darken-4 {
	background-color: rgba(0, 0, 0, 0.5);
}

/*--------------------------------------------------
404 Page
---------------------------------------------------*/
.error-page-container .big-error-number {
	font-size: 6.5rem;
	line-height: 6.5rem;
}

.error-page-container h2 {
	font-size: 1rem;
}

@media (min-width: 40em) {
	.error-page-container .big-error-number {
		font-size: 14.5rem;
		line-height: 14.5rem;
	}

	.error-page-container h2 {
		font-size: 2rem;
	}
}

/*--------------------------------------------------
Back To Top
---------------------------------------------------*/
.backtotop {
	height: 48px;
	width: 48px;
	border-radius: 50%;
	bottom: 1rem;
	right: 1rem;
	text-indent: 100%;
	white-space: nowrap;
	background: rgba(0, 0, 0, 0.8) url(../images/top-arrow.svg) no-repeat center 50%;
	visibility: hidden;
	opacity: 0;
	-webkit-transition: opacity .3s 0s, visibility 0s .3s;
	-moz-transition: opacity .3s 0s, visibility 0s .3s;
	transition: opacity .3s 0s, visibility 0s .3s;
}

.backtotop.is-visible,
.backtotop.fade-out,
.no-touch .backtotop:hover {
	-webkit-transition: opacity .3s 0s, visibility 0s 0s;
	-moz-transition: opacity .3s 0s, visibility 0s 0s;
	transition: opacity .3s 0s, visibility 0s 0s;
}

.backtotop.is-visible {
	visibility: visible;
	opacity: 1;
}

.backtotop.fade-out {
	opacity: .5;
}

.no-touch .backtotop:hover {
	background-color: #333;
	opacity: 1;
}

/*--------------------------------------------------
Blog
---------------------------------------------------*/
.blog h2 a:hover {
	text-decoration: none;
	color: #efcc00;
}

.blog .button:hover {
	color: #efcc00;
}

.blog .item {
	max-width: 640px;
}

.blog-item .pagination .button {
	padding: 0;
}

.blog-item .pagination .button:hover {
	color: #efcc00;
}

/*--------------------------------------------------
Columns for article
---------------------------------------------------*/
ul.columns {
	overflow: hidden;
	list-style: none;
	padding: 0;
}

ul.columns li:last-child {
	margin-right: 0;
}

.column {
	float: left;
	box-sizing: border-box;
	margin-right: 4%;
}

.columns-1 .column,
.columns-2 .column,
.columns-3 .column,
.columns-4 .column,
.columns-5 .column {
	width: 100%;
}

@media (min-width: 52em) {
	.columns-1 .column {
		width: 100%;
	}

	.columns-2 .column {
		width: 48%;
	}

	.columns-3 .column {
		width: 30.6%;
	}

	.columns-4 .column {
		width: 22%;
	}

	.columns-5 .column {
		width: 16.8%;
	}
}

/*--------------------------------------------------
Contact
---------------------------------------------------*/
.contact .prefix {
	width: 120px;
	display: inline-block;
}

.contact .email {
	-webkit-transition: all 0.5s ease;
	transition: all 0.5s ease;
}

.contact .email:hover {
	color: #efcc00;
	text-decoration: none;
}

/*--------------------------------------------------
Footer
---------------------------------------------------*/
footer {
	height: 80px;
	line-height: 80px;
	border: 1px solid #eee;
}

footer .social {
  float: right;
}

footer .social a {
	float: left;
	line-height: 80px;
	text-decoration: none;
	color: white;
	cursor: pointer;
	font-size: 1.4em;
  padding-right: 3rem;
  padding-left: 3rem;
}

footer .social a:hover {
	background-color: #333;
}


footer .copyright {
  float: left;
}

footer .copyright p {
	font-size: 1rem;
	line-height: 80px;
	text-transform: capitalize;
	letter-spacing: 1px;
	margin: 0;
	text-align: center;
}

footer .copyright p .made {
	display: none;
}

@media (min-width: 52em) {
	footer .copyright p {
		text-align: left;
	}

	footer .copyright p .made {
		display: inline-block;
	}
}

/*--------------------------------------------------
Header
---------------------------------------------------*/
/* 1. General */
.header {
	background: rgba(0, 0, 0, 0.5);
	height: 80px;
	width: 100%;
	z-index: 9999;
}

/* 2. Logo */
.logo {
	top: 50%;
	bottom: auto;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	left: 1rem;
}

.logo a {
	color: white;
	text-decoration: none;
	font-family: "Text Me One", Helvetica, sans-serif;
}

/* 3. Menu */
.main-nav {
	top: 50%;
	bottom: auto;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 5rem;
}

.main-nav a {
	color: white;
	text-decoration: none;
	font-size: 0.875rem;
}

.main-nav a:hover {
	border-bottom: 1px solid #efcc00;
}

.responsive-nav-trigger {
	right: 1rem;
	top: 15px;
	height: 48px;
	width: 48px;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 50%;
}

.responsive-nav-trigger .menu-icon {
	left: 50%;
	top: 50%;
	bottom: auto;
	right: auto;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	-o-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);
	width: 18px;
	height: 2px;
	background-color: white;
	-webkit-transition: background-color 0.3s;
	-moz-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

.responsive-nav-trigger .menu-icon::before, .responsive-nav-trigger .menu-icon::after {
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: white;
	right: 0;
	-webkit-transition: -webkit-transform .3s, top .3s, background-color 0s;
	-moz-transition: -moz-transform .3s, top .3s, background-color 0s;
	transition: transform .3s, top .3s, background-color 0s;
}

.responsive-nav-trigger .menu-icon::before {
	top: -5px;
}

.responsive-nav-trigger .menu-icon::after {
	top: 5px;
}

.responsive-nav-trigger .menu-icon.is-clicked {
	background-color: rgba(255, 255, 255, 0);
}

.responsive-nav-trigger .menu-icon.is-clicked::before,
.responsive-nav-trigger .menu-icon.is-clicked::after {
	background-color: white;
}

.responsive-nav-trigger .menu-icon.is-clicked::before {
	top: 0;
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-ms-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
}

.responsive-nav-trigger .menu-icon.is-clicked::after {
	top: 0;
	-webkit-transform: rotate(225deg);
	-moz-transform: rotate(225deg);
	-ms-transform: rotate(225deg);
	-o-transform: rotate(225deg);
	transform: rotate(225deg);
}

.responsive-nav {
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.5);
	padding: 50px 0;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	-webkit-transform: translateY(-100%);
	-moz-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	-o-transform: translateY(-100%);
	transform: translateY(-100%);
	-webkit-transition-property: -webkit-transform;
	-moz-transition-property: -moz-transform;
	transition-property: transform;
	-webkit-transition-duration: 0.4s;
	-moz-transition-duration: 0.4s;
	transition-duration: 0.4s;
}

.responsive-nav li {
	margin: .2em 0;
	text-transform: capitalize;
}

.responsive-nav li:first-child {
	padding-top: 80px;
}

.responsive-nav li a {
	color: white;
	text-decoration: none;
}

.responsive-nav li a:hover {
	color: #efcc00;
}

.responsive-nav.is-visible {
	-webkit-transform: translateY(0);
	-moz-transform: translateY(0);
	-ms-transform: translateY(0);
	-o-transform: translateY(0);
	transform: translateY(0);
}

/* 4. Hero */
.large-header {
	box-sizing: border-box;
	width: 100%;
	padding: 0;
	position: relative;
	display: -webkit-box;
	display: -moz-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

.bg-blue,
.bg-red,
.bg-green,
.bg-yellow,
.bg-dark-gray,
.bg-mid-gray,
.bg-light-gray,
.bg-lighter-gray {
	-webkit-animation: color-pulse 10s infinite;
	-webkit-animation: color-pulse 10s infinite;
	-moz-animation: color-pulse 10s infinite;
	-o-animation: color-pulse 10s infinite;
	animation: color-pulse 10s infinite;
	background-image: url("../images/noise.png");
}

.hero-title {
	padding: 0;
	top: 50%;
	left: 50%;
	-webkit-transform: translate3d(-50%, -50%, 0);
	transform: translate3d(-50%, -50%, 0);
	color: #fff;
	font-weight: normal;
}

.hero-title p {
	font-size: 2.5rem;
	line-height: 2.5rem;
}

.page-title {
	font-size: 2rem;
	color: #fff;
}

.breadcrumb {
	padding: 0;
}

.breadcrumb li:before {
	content: "/";
	color: #efcc00;
}

.breadcrumb li:first-child .button-narrow {
	padding-left: 0;
}

.breadcrumb li:first-child:before {
	display: none;
}

.breadcrumb a {
	color: #efcc00;
}

.breadcrumb a:hover {
	color: #fff;
}

/* 5. Responsive */
@media (min-width: 40em) {
	.header {
		height: 120px;
		background: transparent;
		box-shadow: none;
	}

	.hero-title p {
		font-size: 4rem;
		line-height: 4rem;
	}

	.responsive-nav-trigger {
		right: 5rem;
	}

	.logo {
		left: 5rem;
	}

	.page-title {
		font-size: 2.5rem;
		color: #fff;
	}
}

@media (min-width: 64em) {
	.header {
		-webkit-transition: background-color 0.3s;
		-moz-transition: background-color 0.3s;
		transition: background-color 0.3s;
    /* Force Hardware Acceleration in WebKit */
		-webkit-transform: translate3d(0, 0, 0);
		-moz-transform: translate3d(0, 0, 0);
		-ms-transform: translate3d(0, 0, 0);
		-o-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
	}

	.header.is-fixed {
		position: fixed;
		top: -120px;
		background: rgba(0, 0, 0, 0.5);
		-webkit-transition: -webkit-transform 0.3s;
		-moz-transition: -moz-transform 0.3s;
		transition: transform 0.3s;
	}

	.header.is-visible {
		-webkit-transform: translate3d(0, 100%, 0);
		-moz-transform: translate3d(0, 100%, 0);
		-ms-transform: translate3d(0, 100%, 0);
		-o-transform: translate3d(0, 100%, 0);
		transform: translate3d(0, 100%, 0);
	}

	.header.menu-is-open {
		background: rgba(0, 0, 0, 0.5);
	}
}

/*--------------------------------------------------
Pagination
---------------------------------------------------*/
.icon {
	width: 1em;
	height: 1em;
	position: relative;
	top: -.125em;
	vertical-align: middle;
	fill: currentcolor;
}

/*--------------------------------------------------
Portfolio
---------------------------------------------------*/
ul.filteroptions {
	height: 60px;
	margin-top: 120px;
}

ul.filteroptions li {
	height: 60px;
	display: inline;
}

ul.filteroptions li a {
	font-size: .875em;
	color: #333;
	line-height: 40px;
	text-decoration: none;
	margin: 0 12px;
	padding: 10px 0;
}

ul.filteroptions li a:hover {
	color: #143893;
}

ul.filteroptions li a.active {
	border-top: 1px solid #efcc00;
	color: #143893;
}

.portfolio .box {
	width: 100%;
}

.portfolio .box a {
	text-decoration: none;
}

.portfolio .box img {
	width: 100%;
}

.portfolio .box:hover .info {
	opacity: 1;
}

.portfolio .box .text {
	color: #fff;
	padding: 4rem 1rem;
	border: none;
	display: block;
}

.portfolio .box .title {
	text-rendering: optimizeLegibility;
	color: #143893;
	font-size: 1.5rem;
	line-height: 1rem;
}

.portfolio .box .type {
	padding-top: 0.5rem;
	color: #777;
	font-size: 1rem;
	text-transform: none;
}

@media (min-width: 40em) {
	.portfolio img {
		width: 100%;
	}
}

@media (min-width: 52em) {
	.portfolio .box {
		width: 47%;
		margin: 1.5%;
	}
}

@media (min-width: 64em) {
	.portfolio .box {
		width: 30%;
		margin: 1.5%;
	}
}

.portfolio-item p:nth-of-type(1) {
	font-size: 1.125rem;
	line-height: 1.875;
}

.portfolio-item .tags p {
	font-size: 0.875rem;
	font-family: "Source Code Pro", Consolas, monospace;
}

.portfolio-item .pagination .button {
	padding: 0;
}

.portfolio-item .pagination .button:hover {
	color: #efcc00;
}

/*   Filtres Isotopes  */
.isotope-item {
	z-index: 2;
}

.isotope-hidden.isotope-item {
	pointer-events: none;
	z-index: 1;
}

.isotope,
.isotope .isotope-item {
  /* change duration value to whatever you like */
	-webkit-transition-duration: 0.8s;
	-moz-transition-duration: 0.8s;
	transition-duration: 0.8s;
}

.isotope {
	-webkit-transition-property: height, width;
	-moz-transition-property: height, width;
	transition-property: height, width;
}

.isotope .isotope-item {
	-webkit-transition-property: -webkit-transform, opacity;
	-moz-transition-property: -moz-transform, opacity;
	transition-property: transform, opacity;
}

/*--------------------------------------------------
Responsive Video
---------------------------------------------------*/
.oembed iframe,.oembed object,.oembed embed,.oembed img{max-width:100%;margin-left:auto !important;margin-right:auto !important;border-width:0}.oembed-video{width:100%;position:relative;padding:0;padding-top:56.25%}.oembed-video iframe,.oembed-video object,.oembed-video img{position:absolute;top:0;left:0;width:100%;height:100%}.oembed-lazyvideo{background-color:#dedede;overflow:hidden}.oembed-lazyvideo .play{z-index:10;position:absolute;top:0;bottom:0;left:0;right:0;opacity:.7;filter:alpha(opacity=70);cursor:pointer;-webkit-transition:opacity 0.4s ease-in-out;transition:opacity 0.4s ease-in-out}.oembed-lazyvideo .play img{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);transform:translate(-50%, -50%);width:auto;height:auto}.oembed-lazyvideo:hover .play{opacity:.4;filter:alpha(opacity=40)}.oembed-lazyvideo .thumb{position:absolute;top:0;bottom:0;left:0;right:0;background-size:cover;background-position:50% 50%;cursor:pointer}.oembed-lazyvideo iframe,.oembed-lazyvideo object{display:none}



/*--------------------------------------------------
Basscss Grid
---------------------------------------------------*/
.container {
	max-width: 64em;
	margin-left: auto;
	margin-right: auto;
}

.col {
	float: left;
	box-sizing: border-box;
}

.col-right {
	float: right;
	box-sizing: border-box;
}

.col-1 {
	width: 8.33333%;
}

.col-2 {
	width: 16.66667%;
}

.col-3 {
	width: 25%;
}

.col-4 {
	width: 33.33333%;
}

.col-5 {
	width: 41.66667%;
}

.col-6 {
	width: 50%;
}

.col-7 {
	width: 58.33333%;
}

.col-8 {
	width: 66.66667%;
}

.col-9 {
	width: 75%;
}

.col-10 {
	width: 83.33333%;
}

.col-11 {
	width: 91.66667%;
}

.col-12 {
	width: 100%;
}

@media (min-width: 40em) {
	.sm-col {
		float: left;
		box-sizing: border-box;
	}

	.sm-col-right {
		float: right;
		box-sizing: border-box;
	}

	.sm-col-1 {
		width: 8.33333%;
	}

	.sm-col-2 {
		width: 16.66667%;
	}

	.sm-col-3 {
		width: 25%;
	}

	.sm-col-4 {
		width: 33.33333%;
	}

	.sm-col-5 {
		width: 41.66667%;
	}

	.sm-col-6 {
		width: 50%;
	}

	.sm-col-7 {
		width: 58.33333%;
	}

	.sm-col-8 {
		width: 66.66667%;
	}

	.sm-col-9 {
		width: 75%;
	}

	.sm-col-10 {
		width: 83.33333%;
	}

	.sm-col-11 {
		width: 91.66667%;
	}

	.sm-col-12 {
		width: 100%;
	}
}

@media (min-width: 52em) {
	.md-col {
		float: left;
		box-sizing: border-box;
	}

	.md-col-right {
		float: right;
		box-sizing: border-box;
	}

	.md-col-1 {
		width: 8.33333%;
	}

	.md-col-2 {
		width: 16.66667%;
	}

	.md-col-3 {
		width: 25%;
	}

	.md-col-4 {
		width: 33.33333%;
	}

	.md-col-5 {
		width: 41.66667%;
	}

	.md-col-6 {
		width: 50%;
	}

	.md-col-7 {
		width: 58.33333%;
	}

	.md-col-8 {
		width: 66.66667%;
	}

	.md-col-9 {
		width: 75%;
	}

	.md-col-10 {
		width: 83.33333%;
	}

	.md-col-11 {
		width: 91.66667%;
	}

	.md-col-12 {
		width: 100%;
	}
}

@media (min-width: 64em) {
	.lg-col {
		float: left;
		box-sizing: border-box;
	}

	.lg-col-right {
		float: right;
		box-sizing: border-box;
	}

	.lg-col-1 {
		width: 8.33333%;
	}

	.lg-col-2 {
		width: 16.66667%;
	}

	.lg-col-3 {
		width: 25%;
	}

	.lg-col-4 {
		width: 33.33333%;
	}

	.lg-col-5 {
		width: 41.66667%;
	}

	.lg-col-6 {
		width: 50%;
	}

	.lg-col-7 {
		width: 58.33333%;
	}

	.lg-col-8 {
		width: 66.66667%;
	}

	.lg-col-9 {
		width: 75%;
	}

	.lg-col-10 {
		width: 83.33333%;
	}

	.lg-col-11 {
		width: 91.66667%;
	}

	.lg-col-12 {
		width: 100%;
	}
}

/*--------------------------------------------------
Basscss Positions
---------------------------------------------------*/
.relative {
	position: relative;
}

.absolute {
	position: absolute;
}

.fixed {
	position: fixed;
}

.top-0 {
	top: 0;
}

.right-0 {
	right: 0;
}

.bottom-0 {
	bottom: 0;
}

.left-0 {
	left: 0;
}

.z1 {
	z-index: 1;
}

.z2 {
	z-index: 2;
}

.z3 {
	z-index: 3;
}

.z4 {
	z-index: 4;
}

.absolute-center {
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	display: table;
}

/*--------------------------------------------------
Basscss UI Utility Button Sizes
---------------------------------------------------*/
.button-small {
	padding: 0.25em 0.5rem;
}

.button-big {
	line-height: 1.625;
}

.button-narrow {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

/*--------------------------------------------------
Basscss Utility Layout
---------------------------------------------------*/
.inline {
	display: inline;
}

.block {
	display: block;
}

.inline-block {
	display: inline-block;
}

.overflow-hidden {
	overflow: hidden;
}

.overflow-scroll {
	overflow: scroll;
}

.overflow-auto {
	overflow: auto;
}

.clearfix:before,
.clearfix:after {
	content: " ";
	display: table;
}

.clearfix:after {
	clear: both;
}

.left {
	float: left;
}

.right {
	float: right;
}

.fit {
	max-width: 100%;
}

.half-width {
	width: 50%;
}

.full-width {
	width: 100%;
}

/*--------------------------------------------------
Basscss Utility Responsive States
---------------------------------------------------*/
.sm-show,
.md-show,
.lg-show {
	display: none !important;
}

@media (min-width: 40em) {
	.sm-show {
		display: block !important;
	}
}

@media (min-width: 52em) {
	.md-show {
		display: block !important;
	}
}

@media (min-width: 64em) {
	.lg-show {
		display: block !important;
	}
}

@media (min-width: 40em) {
	.sm-hide {
		display: none !important;
	}
}

@media (min-width: 52em) {
	.md-hide {
		display: none !important;
	}
}

@media (min-width: 64em) {
	.lg-hide {
		display: none !important;
	}
}

.display-none {
	display: none !important;
}

.hide {
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
}

/*--------------------------------------------------
Basscss Utility Typography
---------------------------------------------------*/
.bold {
	font-weight: bold;
}

.regular {
	font-weight: normal;
}

.italic {
	font-style: italic;
}

.caps {
	text-transform: uppercase;
	letter-spacing: .1em;
}

.left-align {
	text-align: left;
}

.center {
	text-align: center;
}

.right-align {
	text-align: right;
}

.justify {
	text-align: justify;
}

.nowrap {
	white-space: nowrap;
}

/*--------------------------------------------------
Basscss Utility White Space
---------------------------------------------------*/
.m0 {
	margin: 0;
}

.mt0 {
	margin-top: 0;
}

.mr0 {
	margin-right: 0;
}

.mb0 {
	margin-bottom: 0;
}

.ml0 {
	margin-left: 0;
}

.m1 {
	margin: 0.5rem;
}

.mt1 {
	margin-top: 0.5rem;
}

.mr1 {
	margin-right: 0.5rem;
}

.mb1 {
	margin-bottom: 0.5rem;
}

.ml1 {
	margin-left: 0.5rem;
}

.m2 {
	margin: 1rem;
}

.mt2 {
	margin-top: 1rem;
}

.mr2 {
	margin-right: 1rem;
}

.mb2 {
	margin-bottom: 1rem;
}

.ml2 {
	margin-left: 1rem;
}

.m3 {
	margin: 2rem;
}

.mt3 {
	margin-top: 2rem;
}

.mr3 {
	margin-right: 2rem;
}

.mb3 {
	margin-bottom: 2rem;
}

.ml3 {
	margin-left: 2rem;
}

.m4 {
	margin: 4rem;
}

.mt4 {
	margin-top: 4rem;
}

.mr4 {
	margin-right: 4rem;
}

.mb4 {
	margin-bottom: 4rem;
}

.ml4 {
	margin-left: 4rem;
}

.mxn1 {
	margin-left: -0.5rem;
	margin-right: -0.5rem;
}

.mxn2 {
	margin-left: -1rem;
	margin-right: -1rem;
}

.mxn3 {
	margin-left: -2rem;
	margin-right: -2rem;
}

.mxn4 {
	margin-left: -4rem;
	margin-right: -4rem;
}

.mx-auto {
	margin-left: auto;
	margin-right: auto;
}

.p1 {
	padding: 0.5rem;
}

.py1 {
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.px1 {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
}

.p2 {
	padding: 1rem;
}

.py2 {
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.px2 {
	padding-left: 1rem;
	padding-right: 1rem;
}

.p3 {
	padding: 2rem;
}

.py3 {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.px3 {
	padding-left: 2rem;
	padding-right: 2rem;
}

.p4 {
	padding: 4rem;
}

.py4 {
	padding-top: 4rem;
	padding-bottom: 4rem;
}

.px4 {
	padding-left: 4rem;
	padding-right: 4rem;
}

.language + .language {
  margin-left: .2rem;
}

.language + .language:before {
  content: '|';
}

.language .active {
  border-bottom:1px solid #efcc00;
}
.responsive-language .active {
  color: #efcc00;
}

.example {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #fafafa;
  position: relative;
}

.exemple-title {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #eee;
  padding: 0 1rem;
  border-top-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.example + pre {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  /*border: 1px solid #ccc;*/
  /*border-top: 0;*/
}
pre {
  margin-bottom: 2rem;
}

.submenu > div {
  border-bottom: 0;
}

h3 em {
  color: gray;
  font-size: 66%;
}
